











































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class About extends Vue {
  private publicPath = process.env.BASE_URL;

  mounted() {
    document.title = 'Lancelot Blanchard | About';
  }
}
